html, body {
  height: 100%;
  flex-direction: column;
  margin: 0;
  display: flex;
}

a {
  color: gray;
}

.header {
  max-height: fit-content;
  align-items: center;
  font-family: Lato, sans-serif;
  font-size: 40px;
  display: flex;
}

.headerleft {
  flex-grow: 1;
}

.main {
  flex-grow: 1;
  display: flex;
}

.view {
  flex-direction: column;
  display: flex;
}

#map {
  flex-grow: 1;
}

.forecastLegend {
  margin: 5px auto;
  font-family: Lato, sans-serif;
  display: none;
}

footer {
  background: #dbdbdb;
  font-family: Kalam, sans-serif;
  display: flex;
}

.developer {
  text-align: center;
  flex-grow: 1;
}

.credits {
  cursor: pointer;
  padding-top: 2.5px;
  padding-right: 5px;
}

.submit {
  cursor: pointer;
  background-color: #90ee90;
  border: 1px solid #5a5a5a;
}

.license-container {
  margin: auto;
}

.license-subcontainer {
  width: 85%;
  margin: auto;
}

#license {
  width: 100%;
  color: #fff;
  z-index: 1000;
  background-color: brown;
  display: none;
  position: fixed;
  bottom: 0;
}

.license {
  min-height: 80px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  display: flex;
}

#license-btn, #license-btn-more {
  min-width: 120px;
  cursor: pointer;
  color: #fff;
  background-color: #421e1e;
  border: 3px solid #fff;
  border-radius: 5px;
  padding: 8px 12px;
  font-family: inherit;
  font-size: 1rem;
}

.select {
  flex-flow: wrap;
  padding-bottom: 2.5px;
  font-family: Lato, sans-serif;
  display: none;
}

.legend {
  border: 0;
  flex-grow: 0;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Lato, sans-serif;
}

.legend tr, .legend td {
  border: 0;
}

.legend th {
  border: 0;
  padding-right: 40px;
}

.legend span {
  float: left;
  width: 30px;
  height: 12px;
  border: 1px solid #ccc;
  margin: 2px;
}

.legend .lowerHight {
  background-color: #eda8a8;
}

.legend .lowerMedium {
  background-color: #f5d7d7;
}

.legend .lowerLow {
  background-color: #fcebeb;
}

.legend .aroundHight {
  background-color: #f0f03a;
}

.legend .aroundMedium {
  background-color: #fee599;
}

.legend .aroundLow {
  background-color: #fff2cc;
}

.legend .majorHight {
  background-color: #5fa6e3;
}

.legend .majorMedium {
  background-color: #bdd7ee;
}

.legend .majorLow {
  background-color: #deebf6;
}

.legend .noQuality {
  background-color: #a5a5a5;
}

.legendGradient {
  border: 0;
  padding: 5px;
  font-family: Lato, sans-serif;
  display: flex;
}

.headerleft:hover .tooltip-text {
  visibility: visible;
  top: 110px;
  left: 10%;
}

.help-popup {
  width: 60%;
  height: 60%;
  z-index: 1000000;
  background: #f5f5f5;
  border: 1px solid #5a5a5a;
  display: flex;
  position: absolute;
  top: 20%;
  left: 20%;
}

.help-shadow {
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: #ffffff83;
  display: flex;
  position: absolute;
}

.help-content {
  text-align: center;
  padding: 15px;
}

.close-button {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 25px;
}

.login {
  float: right;
  cursor: pointer;
  padding-right: 15px;
  display: flex;
}

#modal-alert {
  color: red;
  align-items: center;
  display: flex;
}

.loader {
  width: 40px;
  height: 40px;
  z-index: 1000;
  border: 5px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: wait;
  background-color: #00000080;
  display: none;
  position: fixed;
  inset: 0;
}

fieldset {
  background-color: #f8f9fa;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0 0 15px #a9b5c480;
}

legend {
  text-shadow: 4px 4px 4px #fff;
  font-family: Lato, sans-serif;
  font-weight: 700;
}

/*# sourceMappingURL=index.4222e934.css.map */
