html,
body {
    /* overflow-y: hidden;
    overflow-x: hidden; */
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0px;
}

a {
    color: gray;
}

.header {
    display: flex;
    font-size: 40px;
    font-family: "Lato", sans-serif;
    align-items: center;
    max-height: fit-content;
}

.headerleft {
    flex-grow: 1;
}

.main {
    display: flex;
    flex-grow: 1;
}

.view {
    display: flex;
    flex-direction: column;
}

#map {
    flex-grow: 1;
}

.forecastLegend {
    font-family: "Lato", sans-serif;
    margin: 5px auto;
    display: none;
}


footer {
    display: flex;
    background: rgb(219, 219, 219);
    font-family: "Kalam", sans-serif;
}

.developer {
    flex-grow: 1;
    text-align: center;
}

.credits {
    padding-top: 2.5px;
    padding-right: 5px;
    cursor: pointer;
}

.submit {
    cursor: pointer;
    border: 1px solid #5a5a5a;
    background-color: lightgreen;
}

.license-container {
    margin: auto;
}

.license-subcontainer {
    width: 85%;
    margin: auto;
}

#license {
    width: 100%;
    position: fixed;
    bottom: 0;
    color: white;
    background-color: brown;
    z-index: 1000;
    display: none;
}

.license {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

#license-btn,
#license-btn-more {
    min-width: 120px;
    border-radius: 5px;
    padding: 8px 12px 8px 12px;
    font-size: 1rem;
    font-family: inherit;
    cursor: pointer;
    border: 3px solid white;
    background-color: rgb(66, 30, 30);
    color: white;
}

.select {
    font-family: "Lato", sans-serif;
    display: none;
    flex-direction: row;
    padding-bottom: 2.5px;
    flex-wrap: wrap;
}

.legend {
    font-family: "Lato", sans-serif;
    border: 0;
    flex-grow: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.legend tr {
    border: 0
}

.legend td {
    border: 0
}

.legend th {
    border: 0;
    padding-right: 40px
}

.legend span {
    border: 1px solid #ccc;
    float: left;
    width: 30px;
    height: 12px;
    margin: 2px;
}

.legend .lowerHight {
    background-color: #EDA8A8;
}

.legend .lowerMedium {
    background-color: #F5D7D7;
}

.legend .lowerLow {
    background-color: #FCEBEB;
}

.legend .aroundHight {
    background-color: #F0F03A;
}

.legend .aroundMedium {
    background-color: #FEE599;
}

.legend .aroundLow {
    background-color: #FFF2CC;
}

.legend .majorHight {
    background-color: #5FA6E3;
}

.legend .majorMedium {
    background-color: #BDD7EE;
}

.legend .majorLow {
    background-color: #DEEBF6;
}

.legend .noQuality {
    background-color: #A5A5A5;
}

.legendGradient {
    display: flex;
    font-family: "Lato", sans-serif;
    border: 0;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.headerleft:hover .tooltip-text {
    visibility: visible;
    top: 110px;
    left: 10%;
}

.help-popup {
    display: flex;
    width: 60%;
    height: 60%;
    background: whitesmoke;
    border: 1px solid #5a5a5a;
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 1000000;
}

.help-shadow {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.514);
    z-index: 100000;
}

.help-content {
    padding: 15px;
    text-align: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 25px;
    cursor: pointer;
}

.login {
    display: flex;
    float: right;
    padding-right: 15px;
    cursor: pointer;
}

#modal-alert {
    color: red;
    display: flex;
    align-items: center;
}

.loader {
    border: 5px solid #f3f3f3; 
    border-top: 5px solid #3498db; 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  
    position: fixed; 
    z-index: 1000; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    display: none; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .overlay {
    position: fixed; 
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
    cursor: wait; 
    display: none; 
  }

  fieldset {
    border: 2px solid #000000;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(169, 181, 196, 0.5);
    background-color: #f8f9fa;
  }
  
  legend {

    font-family: 'Lato', sans-serif; 
    font-weight: 700; 
    text-shadow: 4px 4px 4px #ffffff;
  }